import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileReference } from '../../../dashboards/tile-reference';
import { TileComponent } from '../../../tiles/base/tile.component';
import { RequestState } from '../../../api/request-handler';

@Component({
  selector: 'app-br-line-chart',
  templateUrl: './br-line-chart.component.html',
  styleUrls: ['./br-line-chart.component.scss']
})

export class BrLineChartComponent extends TileComponent implements OnInit {
  RequestStateLink = RequestState;
  defaultPreferences() {
    return {
      showTitle: true
    };
  }

  strChartTitle;
  strTitle;
  strYAxis = '';
  strYAxis2 = '';
  chartTopLimit;
  chartBottomLimit;

  chartValues: any[];

  translateApiDataForChart(apiData: any) {
    const chartStackSeries = [{
      name: '',
      color: '#3498DB',  // '#ff0000',
      type: 'line',
      data: [],
      axis: 'axis1',
      tooltip: {
        visible: true,
        format: '{0}',
        background: '#aa0061',
        padding: '2'
      }
    }];

    // console.log('Test JSON ITEM--', JSON.stringify(apiData));
    this.strChartTitle = apiData.title;
    this.strYAxis = apiData.yAxisLabel;
    this.strYAxis2 = apiData.yAxisLabel2;

    const chartCategories = [];
    let maxValue = 0;
    let minValue = 0;
    let roundSize = 0;

    for (const row of apiData.values) {
      chartCategories.push(row.key);
      const intValue = parseInt(row.value, 10);
      if (intValue > maxValue) {
        maxValue = intValue;
      }
      chartStackSeries[0].data.push(intValue);
    }

    const zero = '0';
    const valueSize = maxValue.toString().length;
    const roundSizeStr = '1'.padEnd(valueSize, zero);
    roundSize = 10;
    maxValue = this.roundUp(maxValue * 1.2, roundSize);

    if (this.apiData.chartTopLimit) { maxValue = this.apiData.chartTopLimit; }
    if (this.apiData.chartBottomLimit) { minValue = this.apiData.chartBottomLimit; }
    if (this.apiData.yAxisMajorUnit) { roundSize = this.apiData.yAxisMajorUnit; }

    // console.log('API DATA', this.apiData);

    const valueAxis1 = {
      name: 'axis1',
      labels: {
        // https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
        // cannot currently add format of #K, add issue to https://github.com/telerik/kendo-intl/issues
        format: '{0}',
        content: (e) => {
          return e.value;
        },
        position: 'start',
        visible: true
      },
      max: maxValue,
      min: minValue,
      majorUnit: roundSize,
      line: {
        visible: false
      },
      title: {
        text: this.strYAxis,
        color: '#000000'
      },
      tooltip: {
        visible: true,
        format: '{0}',
        background: '#aa0061',
        padding: '2'
      }
    };

    this.chartOptions = {
      seriesDefaults: {
        type: 'line',
        area: {
          line: {
            style: 'smooth'
          }
        }
      },
      series: chartStackSeries,
      chartArea: {
        border: {
          color: '#000',
          width: 1,
          dashType: 'solid'
        }
      },
      valueAxis: [valueAxis1], // removed valueAxis2
      categoryAxis: {
        categories: chartCategories,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45
        }
      }
    };
    // throw new Error('Method not implemented.');
  }

  roundUp(value, toTheNearest) {
    return Math.ceil(value / toTheNearest) * toTheNearest;
  }

  isDataFound(value) {
    let dataFound = (value !== undefined && value !== null && (Array.isArray(value.values) && value.values.length > 0));
    let atLeastOneValueNotNull = false;
    if (value !== undefined && 'values' in value) {
      for (const item of value.values) {
        if (item.value !== null) {
          atLeastOneValueNotNull = true;
        }
      }
    }
    if (!atLeastOneValueNotNull) {
      dataFound = false;
    }
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }
}
