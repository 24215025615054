<div class="dashboard">
    <div class="container-fluid">
        <div class="row" *ngIf="state==='new' || state==='start'" style="margin-top:-70px;">
            <div class="col"><h3 style="text-align: left">Configure Presentation</h3></div>
            <div class="col" style="text-align: right;"><app-presentation-select></app-presentation-select></div>
        </div>
        <div class="row">
            <span *ngIf="state==='start' && false" style="margin:auto;">
                <table cellpadding="10px">
                    <tr>
                        <td>
                            <button kendoButton fillMode="flat" style="padding: 40px 30px;font-size: 15pt;" (click)="startNew()">Start a New Presentation</button>
                        </td>
                        <td style="border-left:1px solid #cccccc">
                            <kendo-card width="260px">
                                <kendo-card-header class="k-hstack">
                                    <div>
                                        <h1 kendoCardTitle>Select a Saved Presentation</h1>
                                    </div>
                                </kendo-card-header>
                                <kendo-card-body style="padding: 0px;">
                                    <app-autoselect-list
                                            [state]="RequestState.Complete"
                                            [height]="200"
                                            [hasSearch]="false"
                                            [data]="savedPresentations"
                                            [keyField]="'key'"
                                            [displayField]="'value'">
                                    </app-autoselect-list>
                                </kendo-card-body>
                                <hr kendoCardSeparator/>
                                <kendo-card-actions>
                                    <button kendoButton fillMode="flat" (click)="selectPresentation()">Select</button>
                                </kendo-card-actions>
                            </kendo-card>
                        </td>
                    </tr>
                </table>
            </span>
            <span *ngIf="state==='new' || state==='start'" style="margin:auto;">
                <table>
                    <tr>
                        <td valign="top">
                            <kendo-stepper
                                    [style.width.px]="300"
                                    [steps]="steps"
                                    stepType="full"
                                    orientation="vertical"
                                    [linear]="false"
                                    [(currentStep)]="newPresentationStep"
                                    (currentStepChange)="currentStepChange($event)"
                            >
                            </kendo-stepper>
                            <button kendoButton fillMode="flat" (click)="selectPresentation()">Run</button>&nbsp;
                        </td>
                        <td valign="top" style="background-color: #f9f9f9; width: 820px;">
                            <span *ngIf="showValidationMessages">
                                <div class="validation-error" *ngFor="let errorMsg of validationErrors">
                                    {{errorMsg}}
                                </div>
                            </span>
                            <div *ngIf="newPresentationStep===0">
                                <app-br-location-selector></app-br-location-selector>
                            </div>
                            <div *ngIf="newPresentationStep===1">
                                <app-br-business-segment-selector></app-br-business-segment-selector>
                            </div>
                            <div *ngIf="newPresentationStep===2">
                                <app-account-customer-selector></app-account-customer-selector>
                            </div>
                            <div *ngIf="newPresentationStep===3">
                                <app-br-history-range-selector></app-br-history-range-selector>
                            </div>
                            <div *ngIf="newPresentationStep===4">
                                <app-br-product-selector></app-br-product-selector>
                            </div>
                            <div *ngIf="newPresentationStep===5">
                                <app-br-servicing-group-selector></app-br-servicing-group-selector>
                            </div>
                            <div *ngIf="newPresentationStep===6">
                                <app-br-template-selector></app-br-template-selector>
                            </div>
                        </td>
                    </tr>
                </table>
            </span>
        </div>
        <div *ngIf="state==='selected' && presenetationState === 0" class="row" style="margin-top:-70px;">
            <div style="width: 100%;">
                <app-filter-presentation-header [viewType]="'dashboard'" [rangeLiteral]="'Data Range'"></app-filter-presentation-header>
            </div>
            <div *ngIf="presenetationState === 0" style="padding: 0px 20px;margin: auto;">
                <button kendoButton fillMode="flat" (click)="startOver()">Change Filters</button>
            </div>
        </div>
        <!--Dashboard Grid Output-->
        <div *ngIf="state==='selected' && presenetationState === 0" class="row">
            <div class="col col-12 col-xs-12 col-sm-6 col-md-4 col-xl-3" *ngFor="let tileReference of arrangement">
                <!--{{tileReference.componentName}}-->
                <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []"
                   [queryParams]="getQueryParams()"
                    *ngIf="noDataRegistry[tileReference.apiSource]">
                    <app-br-tile-insert [tileReference]="tileReference"></app-br-tile-insert>
                </a>
                <span *ngIf="!noDataRegistry[tileReference.apiSource]">
                    <app-br-tile-insert [tileReference]="tileReference"></app-br-tile-insert>
                </span>
            </div>
            <div *ngIf="devApiTools" style="border: 1px solid #ffd890;background: #ffffd9;padding: 10px;margin: 2px;">
                Add API Route to Test
                <div *ngFor="let option of testComponentOptions">
                    <button kendoButton fillMode="flat" class="selected-test-option" *ngIf="option===testComponentSelectedOption">{{option}}</button>
                    <button kendoButton fillMode="flat" (click)="selectTestOption(option)" *ngIf="option!==testComponentSelectedOption">{{option}}</button>
                </div><br/>
                <p>EX: /qbr/kpi/total_inbound_calls/thumbnail</p>
                <input [(ngModel)]="apiName">
                <button kendoButton fillMode="flat" (click)="addApiCall()">Add</button>

            </div>
        </div>
        <div *ngIf="state==='selected' && presenetationState === 1">
            <div class="slide-container">
                <div>
                    <app-filter-presentation-header [viewType]="'dashboard'" [rangeLiteral]="'Data Range'"></app-filter-presentation-header>
                </div>
                <div *ngFor="let tileReference of arrangement; let i = index">
                    <app-br-tile-insert [tileReference]="tileReference" *ngIf="slideTileReference === i"></app-br-tile-insert>
                </div>
                <div class="filter-reference">
                    <app-filter-presentation-footer></app-filter-presentation-footer>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="state==='selected'">
    <div *ngIf="presenetationState === 0" style="padding: 20px;">
        <button kendoButton fillMode="flat" (click)="startOver()">Close</button>
    </div>
    <div *ngIf="presenetationState === 1" style="padding: 20px;">
        <button kendoButton fillMode="flat" (click)="previous()">Previous KPI</button>&nbsp;
        <button kendoButton fillMode="flat" (click)="next()">Next KPI</button>&nbsp;
        <button kendoButton fillMode="flat"
                *ngIf="arrangement[slideTileReference].detailLink && noDataRegistry[arrangement[slideTileReference].apiSource]"
                [routerLink]="arrangement[slideTileReference].detailLink"
                [queryParams]="getQueryParams()">Drill Down</button>
    </div>
</div>
<div *ngIf="state==='selected' && presenetationState === 0" class="row">
    <div style="width: 100%; text-align: center;">
        <app-filter-presentation-footer></app-filter-presentation-footer>
    </div>
</div>
