export const environment = {
  proxyServerUrl: 'https://oneview-d.abbott.com/proxy',
  gsrUrl: 'https://gsr.abbott.com',
  apiProxyRouteKey: 'gsmm-p',
  production: true,
  authRedirectUrl: 'https://oneview-d.abbott.com',
  clientId: 'a2e0a7ea-5d97-4b2e-b404-5d0756dcc466',
  tenantId: '5b268d57-2a6f-4e04-b0de-6938583d5ebc'
};
// ng build --configuration=dev --sourceMap=true
// sourceMap=true add ability to track down issues to source location
