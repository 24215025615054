import { Component, OnInit } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import { TileComponent } from '../tile.component';

@ComponentLookup('TextIndicatorsComponent')
@Component({
  selector: 'app-text-indicators',
  templateUrl: './text-indicators.component.html',
  styleUrls: ['./text-indicators.component.scss']
})
export class TextIndicatorsComponent extends TileComponent implements OnInit {
  defaultPreferences() {
    return {
      showTitle: true
    };
  }
  translateApiDataForChart(apiData) {
    // console.log(this.tileReference.apiSource);
    // console.log(JSON.stringify(apiData));
    if (this.isDataFound(apiData)) {
      let instrTotalQty = 0;
      if (this.preferences.calculateTotalFromData === true && 'values' in apiData) {
        for (const productLineInfo of apiData.values) {
          instrTotalQty += productLineInfo.Quantity;
        }
        apiData.totalQuantity = instrTotalQty;
      }
    }
  }
  // isDataFound(apiData) {
  //   return ('values' in apiData && apiData.values.length > 0);
  // }
  getLowerTitleColor(apiData) {
    if ('lowerTitleColor' in this.preferences) {
      return this.preferences.lowerTitleColor;
    }
    return apiData.color;
  }

  getTextSize() {
    if (this.apiData.displayvalue !== undefined) {
      const biggestCharCount = this.apiData.displayvalue.length;
      let maxWidth = 250;
      if (biggestCharCount < 4) {
        maxWidth = 150;
      }
      if (biggestCharCount < 3) {
        maxWidth = 125;
      }
      if (biggestCharCount < 2) {
        maxWidth = 100;
      }
      return (maxWidth / biggestCharCount) + 'px';
    }
    if (this.apiData.totalQuantity !== undefined) {
      const biggestCharCount = this.apiData.totalQuantity.length;
      let maxWidth = 150;
      if (biggestCharCount < 3) {
        maxWidth = 125;
      }
      if (biggestCharCount < 2) {
        maxWidth = 100;
      }
      return (maxWidth / biggestCharCount) + 'px';
    }
    return '50px';
  }
}
