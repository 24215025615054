import { Input, Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileReference } from '../../../dashboards/tile-reference';
import { TileComponent } from '../../../tiles/base/tile.component';

@Component({
  selector: 'app-br-assay-usage-indicator',
  templateUrl: './br-assay-usage-indicator.component.html',
  styleUrls: ['./br-assay-usage-indicator.component.scss']
})


export class BrAssayUsageIndicatorComponent extends TileComponent implements OnInit {
  protected defaultPreferences() {
    return {
      showTitle: true
    };
  }

  title = 'Assay Test Count Usage per Inst';
  censusColor = 'gray';
  hourColor = 'gray';
  dayColor = 'gray';

  censusValue = 'N/A';
  hourValue = 'N/A';
  dayValue = 'N/A';

  translateApiDataForChart(apiData: any) {
    //console.log('Test JSON ITEM--', JSON.stringify(apiData));
    //console.log('API Data: ', apiData.values);
    this.title = apiData.title;
    if (apiData.values['census'].censusValue) { this.censusValue = apiData.values['census'].censusValue; }
    if (apiData.values['census'].censusColor) { this.censusColor = apiData.values['census'].censusColor };
    if (apiData.values['hour'].hourValue) { this.hourValue = apiData.values['hour'].hourValue };
    if (apiData.values['hour'].hourColor) { this.hourColor = apiData.values['hour'].hourColor };
    if (apiData.values['day'].dayValue) { this.dayValue = apiData.values['day'].dayValue };
    if (apiData.values['day'].dayColor) { this.dayColor = apiData.values['day'].dayColor; };
    // throw new Error('Method not implemented.');
  }

  isDataFound(apiData) {
    const dataFound = super.isDataFound(apiData);
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }

}
