import { Injector } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ParameterResolverService} from './parameter-resolver.service';
import {AuthenticationService} from '../authentication/authentication.service';
import {MockData} from './mock-data';
import {Observable, of, throwError, pipe} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {ApiMapping} from '../configuration/api-mapping';
import {ParamMapping} from '../configuration/param-mapping';
import {CachedResponseRepoService} from './cached-response-repo.service';
import { environment } from '../../environments/environment';
import {StackRequestService} from './stack-request.service';

export enum RequestState {
  Inactive, // initial state: request not ordered yet
  Waiting, // request not ordered yet due to pre requisite, must be set outside
  NotCallable, // pre requisite failed, must be set outside
  Loading, // request has been started, waiting for response
  Complete, // request has been completed
  Error, // request failed,
  SQLError,
  QueryOverload,
  NetworkError,
  NoData
}

/**
 * info: https://medium.com/angular-in-depth/the-best-way-to-unsubscribe-rxjs-observable-in-the-angular-applications-d8f9aa42f6a0
 */
export class RequestHandler {
  apiUrl = environment.proxyServerUrl + '/proxy2.php';
  http: HttpClient;
  auth: AuthenticationService;
  pr: ParameterResolverService;
  cache: CachedResponseRepoService;
  stackRequest: StackRequestService;
  ref: string;

  private mockMode = false;

  private subscription;

  private state: RequestState = RequestState.Inactive;
  private stateChangeCallbacks: any[] = [];

  private response;
  private error;

  private cancelSubscribeCallsCount = 0;

  private cacheKey = null;

  // route = '';
  /**
   * note this constructor is not called via angular's service container
   * instead all dependencies are injected via the api service
   */
  constructor(
    injector: Injector,
    ref: string
  ) {
    this.http = injector.get(HttpClient);
    this.auth = injector.get(AuthenticationService);
    this.pr = injector.get(ParameterResolverService);
    this.cache = injector.get(CachedResponseRepoService);
    this.stackRequest = injector.get(StackRequestService);
    this.ref = ref;
  }

  public cancelSubscription() {
    if (this.subscription && this.subscription !== null) {
      this.cancelSubscribeCallsCount++;
      this.subscription.unsubscribe();
      this.subscription = null;
      if (this.cacheKey) {
        this.cache.clearActiveRequest(this.cacheKey);
        this.cacheKey = null;
      }
    }
  }

  public getCancelSubscribeCallsCount() {
    return this.cancelSubscribeCallsCount;
  }

  public getRef() {
    return this.ref;
  }

  private getParamKeyFromSourceKey(sourceKey) {
    for (const paramKey in ParamMapping) {
      if (paramKey in ParamMapping) {
        const param = ParamMapping[paramKey];
        if (sourceKey === param.sourceKey) {
          return paramKey;
        }
      }
    }
    return null;
  }

  private getSourceKeyForParamKey(paramKey) {
    return ParamMapping[paramKey].sourceKey;
  }

  /**
   * determines which api params trigger refresh events when changed
   * @param route
   * @returns {any[]}
   */
  public getRelativeParams(route) {
    if ('params_to_include' in ApiMapping[route]) {
      const paramKeysToInclude = [];
      for (const paramSourceKeyIndex in ApiMapping[route].params_to_include) {
        if (paramSourceKeyIndex in ApiMapping[route].params_to_include) {
          const paramSourceKey = ApiMapping[route].params_to_include[paramSourceKeyIndex];
          paramKeysToInclude.push(this.getSourceKeyForParamKey(paramSourceKey));
        }
      }
      // console.log(route, paramKeysToInclude);
      return paramKeysToInclude;
    }
    return [];
  }

  // create a request state concept and a resend method
  public sendRequest(route, parameters?, debug = false) {
    // console.log('sendRequest', route, parameters);
    // if (this.mockMode === true && route in MockData) {
    //   // const metricCall = new Subject();
    //   // metricCall.next(MockData[route]);
    //   return of({body: MockData[route]});
    // }
    return this.callApi(route, parameters, debug);
  }

  // @todo: consider handling auth
  //  - https://blog.angular-university.io/rxjs-switchmap-operator/
  protected callApi(route, parameters?, debug = false) {
    // this.route = route;
    if (!this.pr.filtersReady(route, debug)) {
      return false;
    }
    const params = this.pr.getParametersForRoute(route, parameters);
    // console.log('callApi', route, JSON.stringify(params)); // performance_debug
    // console.log('p', JSON.stringify(params));
    let finalRoute;
    if (ApiMapping[route].apiOverride !== undefined) {
      finalRoute = ApiMapping[route].apiOverride;
    } else {
      finalRoute = route;
    }
    const cacheKey = this.cache.formatKey({r: route, p: params});
    if (debug) {
      console.log('cacheKey', cacheKey); // , this.cache.getAllActiveRequest());
    }
    if (this.cache.isCached(cacheKey)) {
      const responseData = this.cache.getResponse(cacheKey);
      // if (!this.subscription || this.subscription === null) {
      this.subscription = new Observable(subscriber => {})
        .subscribe((apiData) => {
        if (apiData instanceof HttpErrorResponse) {
          this.error = apiData;
          this.changeState(RequestState.Error);
        } else if (apiData instanceof HttpResponse) {
          this.response = apiData;
          this.changeState(RequestState.Complete);
        }
      });
      // }
      this.subscription.next(responseData);
    } else if (this.cache.isActiveRequest(cacheKey)) {
      return this.cache.getActiveRequest(cacheKey);
    } else {
      let calcRoute = '';
      if (finalRoute.includes('control_tower') ||
        finalRoute.includes('pushnotifications') ||
        finalRoute.includes('predictive_system_alerts') ||
        finalRoute.includes('global_service_alerts')
      ) {
        calcRoute = finalRoute;
      } else {
        calcRoute = environment.apiProxyRouteKey + finalRoute;
      }
      this.cancelSubscription();
      // const stackKey = this.stackRequest.getStackKey(params, ['metricid']);
      // const requestUniqueParams = this.stackRequest.getRequestUniqueParams(params, ['metricid']);
      // this.stackRequest.addToStack(finalRoute, requestUniqueParams, stackKey);
      setTimeout(() => {
        // const stack = this.stackRequest.getStack(stackKey);
        // console.log(stack);
        const formData = new HttpParams({
          fromObject: {
            route: calcRoute,
            post: JSON.stringify(params),
            // stack: JSON.stringify(stack)
          }
        });
        const httpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded');
        this.subscription = this.http.post(this.apiUrl + '?route=' + finalRoute, formData,
          {
            headers: httpHeaders,
            responseType: 'text',
            withCredentials: true,
            observe: 'response'
          })
          .pipe(
            catchError((error) => {
              this.error = error;
              this.changeState(RequestState.Error);
              return throwError('There was an error calling ' + finalRoute + '.');
            })
          )
          .subscribe((apiData) => {
            if (apiData instanceof HttpErrorResponse) {
              this.error = apiData;
              this.changeState(RequestState.Error);
              this.cache.clearActiveRequest(cacheKey);
            } else if (apiData instanceof HttpResponse) {
              this.response = apiData;
              if (!('cache' in ApiMapping[route]) || ApiMapping[route].cache === true) {
                this.cache.cacheResponse(cacheKey, apiData);
              }
              this.changeState(RequestState.Complete);
            }
          });
      }, 100);
      this.changeState(RequestState.Loading);
      this.cache.cacheActiveRequest(cacheKey, this.subscription);
      this.cacheKey = cacheKey;
    }

    return this.subscription;
  }

  public getResponse() {
    return this.response;
  }
  public getError() {
    return this.error;
  }

  public onStateChange(callback) {
    this.stateChangeCallbacks.push(callback);
    // send the current state to the added callback
    callback(this.state);
    return this;
  }

  public removeStateListener(callback) {
    for (let i = 0; i < this.stateChangeCallbacks.length; i++) {
      if (callback === this.stateChangeCallbacks[i]) {
        this.stateChangeCallbacks.splice(i, 1);
        break;
      }
    }
    return this;
  }

  protected changeState(state: RequestState) {
    // if (this.route === '/customer/details') {
    //   console.log('state1', state);
    // }
    this.state = state;
    for (const callback of this.stateChangeCallbacks) {
      callback(this.state);
    }
    return this;
  }

  public setAsNotCallable() {
    this.cancelSubscription();
    this.changeState(RequestState.NotCallable);
    return this;
  }

  public setAsWaiting() {
    this.cancelSubscription();
    this.changeState(RequestState.Waiting);
    return this;
  }

  public setAsInactive() {
    this.cancelSubscription();
    this.changeState(RequestState.Waiting);
    return this;
  }

  // C360 Example with Auth
  // request(params) {
  //   this.requestState = RequestStates.loading;
  //   // if (!this.subscription) { // disabled 2019-09-11
  //   this.processStateUpdateCallbacks();
  //   this.processRequestStartCallbacks();
  //   // }
  //   const url = this.endPointRoute + this.buildQueryString(params);
  //   this.cancelSubscription();
  //   this.authenticationService.getToken((apiToken: ApiToken) => {
  //     this.subscription = this.http.get(url, {
  //       headers: {
  //         'x-auth-token': apiToken.token
  //       }
  //       // ,observe: 'response'
  //     })
  //       .subscribe(
  //         response => {
  //           // console.log(response.status);
  //           //https://stackoverflow.com/questions/43683052/get-status-code-http-get-response-angular2
  //           if (response !== null) {
  //             this.requestState = RequestStates.complete;
  //           } else {
  //             this.requestState = RequestStates.noData;
  //           }
  //           // clean dates in response to date objects
  //           response = JSON.parse(JSON.stringify(response));
  //           // response = this.JSON_Parse(response, this.dateParser);
  //           response = this.processListValueTranslations(response);
  //           this.processCompleteCallbacks(response);
  //           this.responseCache = response;
  //           this.processStateUpdateCallbacks();
  //         },
  //         (error: HttpErrorResponse) => {
  //           if (error.error instanceof ErrorEvent) {
  //             // Client-side or network error.
  //             this.requestState = RequestStates.networkError;
  //             this.processErrorCallbacks(ErrorTypes.httpConnection, error);
  //           } else {
  //             // API returned an unsuccessful response code.
  //             this.requestState = RequestStates.remoteServerError;
  //             this.processErrorCallbacks(ErrorTypes.hostServer, error);
  //           }
  //           this.responseCache = undefined; // Added 2019-09-09
  //           this.processStateUpdateCallbacks();
  //           if (!error.hasOwnProperty('url') || !error.url.includes("abbottLink")) {
  //             this.authenticationService.refreshLogin();
  //           }
  //         }
  //       );
  //   });
  //   return this;
  // }
}
