<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
            <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                <div *ngIf="preferences.renderForServiceContracts === true" 
                    style='font-weight: 500; font-size: 12pt; text-align: center; color: black'>{{apiData.subtitle}}</div>
                <!-- <div *ngIf="chartOptions !== null">
                            <kendo-chart
                                    [legend]="chartOptions.legend"
                                    [series]="chartOptions.series">
                            </kendo-chart>
                        </div> -->
                <div *ngIf="chartOptions !== null">
                    <kendo-chart (render)="onRender($event)">
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                    [visual]="visualHandler"
                                    type="pie" [data]="chartOptions.pieData"
                                    categoryField="category" field="value" [border]="{color: '#000',width: 1, dashType: 'solid'}"
                                    [tooltip]="{visible: true,format: '{0}%'}"
                                    >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-legend position="bottom" orientation="horizontal" [item]="{ visual: legendItemVisual }"></kendo-chart-legend>
                    </kendo-chart>
                </div>
            </a>                
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
            No data found
        </div>
    </div>
</app-state-handler>