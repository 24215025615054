export const ListViewPages = {
  '/businessReview/pm_tickets/list_view': {
    apiTitle: 'PREVENTIVE MAINTENANCE (PM) COMPLETED TICKETS LIST',
    apiSource: '/qbr/kpi/pm_tickets/details',
    table: {
      columns: [
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'LN', description: 'LIST NUMBER', width: 100, hidden: true },
        { ref: 'INST_STATUS', description: 'INST STATUS', width: 100, hidden: true },
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'ERP_CUSTOMER', description: 'ERP CUST NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'SEASREPORTID', description: 'SEAS CATETORY', width: 100, hidden: true },
        { ref: 'SEASCODE', description: 'SEAS CODE', width: 100, hidden: true },
        { ref: 'SERVICE_CLOSED_DT', description: 'SERVICE CLOSED DATE', width: 100, hidden: true },
        { ref: 'STATE_PROV', description: 'STATE/PROV', width: 100, hidden: true },
        { ref: 'TICKET_3MA', description: 'TICKET (3MA)', width: 100, hidden: true },
        { ref: 'TICKET_6MA', description: 'TICKET (6MA)', width: 100, hidden: true },
        { ref: 'TICKET_12MA', description: 'TICKET (12MA)', width: 100, hidden: true },
        { ref: 'TICKET_PERIOD', description: 'TICKET CLOSED PERIOD', width: 100, hidden: true }
      ]
    }
  },
  
  '/businessReview/nps/list_view': {
    apiTitle: 'NET PROMOTER SCORE (NPS) SURVEYS LIST',
    apiSource: '/qbr/kpi/nps/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'FSR_NAME', description: 'FSR NAME', width: 100, hidden: true },
        { ref: 'INTEVIEW', description: 'INTERVIEW DATE', width: 100, hidden: true },
        { ref: 'OVERALL', description: 'OVERALL', width: 100, hidden: true },
        { ref: 'FIELD', description: 'FIELD SERVICE', width: 100, hidden: true },
        { ref: 'TECHNICAL', description: 'TECHNICAL SUPPORT', width: 100, hidden: true },
        { ref: 'SALES', description: 'SALES', width: 100, hidden: true },
        { ref: 'DELIVERY', description: 'PRODUCT DELIVERY', width: 100, hidden: true },
        { ref: 'QUALITY', description: 'PRODUCT QUALITY', width: 100, hidden: true }
      ]
    }
  },
  
  '/businessReview/uptime/list_view': {
    apiTitle: 'UPTIME TICKETS LIST',
    apiSource: '/qbr/kpi/uptime/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'ERP_CUSTOMER', description: 'ERP CUST NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        //{ ref: 'SEASREPORTID', description: 'SEAS CATETORY', width: 100, hidden: true },
        //{ ref: 'SEASCODE', description: 'SEAS CODE', width: 100, hidden: true },
        { ref: 'FSR_NAME', description: 'FSR NAME', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'UPTIME_24', description: 'UPTIME - 24 HOURS', width: 100, hidden: true },
        { ref: 'UPTIME_BUS', description: 'UPTIME - BUSINESS HOURS', width: 100, hidden: true },
        { ref: 'UPTIME_EXT', description: 'UPTIME - EXTENDED BUSINESS HOURS', width: 100, hidden: true },
        { ref: 'UPTIME_CONTRACT', description: 'UPTIME - CONTRACT HOURS', width: 100, hidden: true },
        { ref: 'SCHED', description: 'CONTRACT SCHEDULE', width: 100, hidden: true },
        //{ ref: 'BS', description: 'BUSINESS SEGMENT', width: 100, hidden: true },
        //{ ref: 'SG', description: 'SERVICING GROUP', width: 100, hidden: true }
      ]
    }
  },

  '/businessReview/tsb/list_view': {
    apiTitle: 'TECHNICAL SERVICE BULLETINS (TSB) COMPLETED LIST',
    apiSource: '/qbr/kpi/tsb/details',
    table: {
      columns: [
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'STATE_PROV', description: 'STATE/PROVINCE', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'INST_STATUS', description: 'INST STATUS', width: 100, hidden: true },
        { ref: 'SEASREPORTID', description: 'SEAS CATETORY', width: 100, hidden: true },
        { ref: 'SEASCODE', description: 'SEAS CODE', width: 100, hidden: true },
        { ref: 'TSB', description: 'TSB', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'ERP_CUSTOMER', description: 'ERP CUST NUMBER', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/response_time/list_view': {
    apiTitle : 'RESPONSE TIME TICKETS LIST',
    apiSource: '/qbr/kpi/response_time/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'STATE_PROV', description: 'STATE/PROVINCE', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'TICKET_PRIORITY', description: 'TICKET PRIORITY', width: 100, hidden: true },
        { ref: 'STATUS', description: 'TICKET STATUS', width: 100, hidden: true },
        { ref: 'CONTRACTS', description: 'CONTRACTS', width: 100, hidden: true },
        { ref: 'SITE_VISIT_FLAG', description: 'SITE VISIT', width: 100, hidden: true },
        { ref: 'DISPATCHED_FLG', description: 'DISPATCHED', width: 100, hidden: true },
        { ref: 'DT_OPENED', description: 'DATE OPENED', width: 100, hidden: true },
        { ref: 'DISPATCHED_DT', description: 'DATE DISPATCHED', width: 100, hidden: true },
        { ref: 'END_TRAVEL_TIME', description: 'END TRAVEL TIME', width: 100, hidden: true },
        { ref: 'ACTIVITY_START_DT', description: 'ACTIVITY START DATE', width: 100, hidden: true },
        { ref: 'SERVICE_CLOSED_DT', description: 'SERVICE CLOSED DATE', width: 100, hidden: true },
        { ref: 'DUE_DT', description: 'TICKET DUE DATE', width: 100, hidden: true },
        { ref: 'RESPONSE_TIME_ORDER', description: 'RESPONSE TIME ORDER', width: 100, hidden: true },
        { ref: 'RESPONSE_TIME_HOURS', description: 'RESPONSE TIME HOURS', width: 100, hidden: true },
        { ref: 'METRIC_USED', description: 'METRIC USED', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/phn/list_view': {
    apiTitle: 'PHN TICKETS LIST',
    apiSource: '/qbr/kpi/phn/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'TICKET_PRIORITY', description: 'TICKET PRIORITY', width: 100, hidden: true },
        { ref: 'TICKET_TYPE', description: 'TICKET TYPE', width: 100, hidden: true },
        { ref: 'TICKET_SERVICE_TYPE', description: 'TICKET SERVICE TYPE', width: 100, hidden: true },
        { ref: 'STATUS', description: 'TICKET STATUS', width: 100, hidden: true },
        { ref: 'EXPERIENCE_CODE', description: 'EXPERIENCE CODE', width: 100, hidden: true },
        { ref: 'EXPERIENCE_CODE_DESC', description: 'EXPERIENCE CODE DESCRIPTION', width: 100, hidden: true },
        { ref: 'EXPERIENCE_BUCKET_DESC', description: 'EXPERIENCE BUCKET DESCRIPTION', width: 100, hidden: true },
        { ref: 'SUMMARY_DESC', description: 'SUMMARY DESCRIPTION', width: 100, hidden: true },
        { ref: 'RECEIVED_DT', description: 'TICKET RECEIVED DATE', width: 100, hidden: true },
        { ref: 'SERVICE_CLOSED_DT', description: 'TICKET SERVICE CLOSED DATE', width: 100, hidden: true },
        { ref: 'TICKET_DURATION', description: 'TICKET DURATION', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/instrument_test_counts/list_view': {
    apiTitle: 'Annualized Average Instrument Test Counts',
    apiSource: '/qbr/kpi/instrument_test_counts/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 160, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'ASSAY', description: 'ASSAY NAME/NUMBER', width: 100, hidden: true },
        { ref: 'TEST_COUNTS', description: 'TEST COUNTS', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/assay_test_counts/list_view': {
    apiTitle: 'Top Assays',
    apiSource: '/qbr/kpi/assay_test_counts/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 160, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'ASSAY', description: 'ASSAY NAME/NUMBER', width: 100, hidden: true },
        { ref: 'TEST_COUNTS', description: 'TEST COUNTS', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/assay_usage/list_view': {
    apiTitle: 'Assay Test Count Usage',
    apiSource: '/qbr/kpi/assay_usage/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 160, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'COUNT_DATETIME', description: 'COUNT DATE TIME', width: 100, hidden: true },
        { ref: 'TEST_COUNTS', description: 'TEST COUNTS', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/mttr/list_view': {
    apiTitle: 'MEAN TIME TO REPAIR (MTTR) TICKETS LIST',
    apiSource: '/qbr/kpi/mttr/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'TICKET_TYPE', description: 'TICKET TYPE', width: 100, hidden: true },
        { ref: 'TICKET_CATEGORY', description: 'TICKET CATEGORY', width: 100, hidden: true },
        { ref: 'LABOR_HOURS', description: 'LABOR HOURS', width: 100, hidden: true },
        { ref: 'DT_OPENED', description: 'DATE OPENED', width: 100, hidden: true },
        { ref: 'DT_CLOSED', description: 'DATE CLOSED', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/ticket_history/list_view': {
    apiTitle: 'TICKET HISTORY',
    apiSource: '/qbr/kpi/ticket_history/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'TICKET_TYPE', description: 'TICKET TYPE', width: 100, hidden: true },
        { ref: 'TICKET_SERVICE_TYPE', description: 'TICKET SERVICE TYPE', width: 100, hidden: true },
        { ref: 'COMPLAINT', description: 'COMPLAINT', width: 100, hidden: true },
        { ref: 'REMOTE', description: 'REMOTE', width: 100, hidden: true },
        { ref: 'FSV', description: 'FIELD SERVICE VISIT', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/ticket_errors/list_view': {
    apiTitle: 'Top Issues',
    apiSource: '/qbr/kpi/ticket_errors/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'TICKETT_TYPE', description: 'TICKET TYPE', width: 100, hidden: true },
        { ref: 'STATUS', description: 'TICKET STATUS', width: 100, hidden: true },
        { ref: 'ERROR_CODE', description: 'ERROR CODE', width: 100, hidden: true },
        { ref: 'ISSUE_CODE', description: 'ISSUE CODE', width: 100, hidden: true },
        { ref: 'ISSUE_CD_DESC', description: 'ISSUE CODE DESCRIPTION', width: 100, hidden: true },
        { ref: 'DATE_OPENED', description: 'DATE OPENED ISO', width: 100, hidden: true },
        { ref: 'SERVICE_CLOSED', description: 'SERVICE CLOSED DATE', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/ticket_errors/timeline_view': {
    apiTitle: 'Top Issues',
    apiSource: '/qbr/kpi/ticket_errors/timeline',
    apiFolder: 'ticket_errors',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/ticket_errors/system_comparison_view': {
    apiTitle: 'Top Issues',
    apiSource: '/qbr/kpi/ticket_errors/system_comparison',
    apiFolder: 'ticket_errors',
    apiChartType: 'MultiBar',
    table: {
      columns: [
          { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
          { ref: 'values', description: 'TICKETS', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/example_data/list_view': {
    apiTitle: 'EXAMPLE DATA',
    apiSource: '/qbr/kpi/example_data/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'TICKET', description: 'TICKET', width: 100, hidden: true },
        { ref: 'FSR_NAME', description: 'FSR NAME', width: 100, hidden: true },
        { ref: 'INTEVIEW', description: 'INTERVIEW DATE', width: 100, hidden: true },
        { ref: 'OVERALL', description: 'OVERALL', width: 100, hidden: true },
        { ref: 'FIELD', description: 'FIELD SERVICE', width: 100, hidden: true },
        { ref: 'TECHNICAL', description: 'TECHNICAL SUPPORT', width: 100, hidden: true },
        { ref: 'SALES', description: 'SALES', width: 100, hidden: true },
        { ref: 'DELIVERY', description: 'PRODUCT DELIVERY', width: 100, hidden: true },
        { ref: 'QUALITY', description: 'PRODUCT QUALITY', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/example_data/timeline_view': {
    apiTitle: 'EXAMPLE DATA',
    apiSource: '/qbr/kpi/example_data/timeline',
    table: {
      columns: [
        { ref: 'M1', description: '2022-06', width: 100, hidden: true },
        { ref: 'M2', description: '2022-05', width: 100, hidden: true },
        { ref: 'M3', description: '2022-04', width: 100, hidden: true },
        { ref: 'M4', description: '2022-03', width: 100, hidden: true },
        { ref: 'M5', description: '2022-02', width: 100, hidden: true },
        { ref: 'M6', description: '2022-01', width: 100, hidden: true },
        { ref: 'M7', description: '2021-12', width: 100, hidden: true },
        { ref: 'M8', description: '2021-11', width: 100, hidden: true },
        { ref: 'M9', description: '2021-10', width: 100, hidden: true },
        { ref: 'M10', description: '2021-09', width: 100, hidden: true },
        { ref: 'M11', description: '2021-08', width: 100, hidden: true },
        { ref: 'M12', description: '2021-07', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/nps/timeline_view': {
    apiTitle: 'NPS',
    apiSource: '/qbr/kpi/nps/timeline',
    table: {
      columns: [
        { ref: 'M1', description: '2022-06', width: 100, hidden: true },
        { ref: 'M2', description: '2022-05', width: 100, hidden: true },
        { ref: 'M3', description: '2022-04', width: 100, hidden: true },
        { ref: 'M4', description: '2022-03', width: 100, hidden: true },
        { ref: 'M5', description: '2022-02', width: 100, hidden: true },
        { ref: 'M6', description: '2022-01', width: 100, hidden: true },
        { ref: 'M7', description: '2021-12', width: 100, hidden: true },
        { ref: 'M8', description: '2021-11', width: 100, hidden: true },
        { ref: 'M9', description: '2021-10', width: 100, hidden: true },
        { ref: 'M10', description: '2021-09', width: 100, hidden: true },
        { ref: 'M11', description: '2021-08', width: 100, hidden: true },
        { ref: 'M12', description: '2021-07', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/instrument_test_counts/timeline_view': {
    apiTitle: 'Annualized Instrument Test Counts',
    apiSource: '/qbr/kpi/instrument_test_counts/timeline',
    apiFolder: 'instrument_test_counts',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/assay_test_counts/timeline_view': {
    apiTitle: 'Top Assays',
    apiSource: '/qbr/kpi/assay_test_counts/timeline',
    apiFolder: 'assay_test_counts',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/assay_usage/timeline_view': {
    apiTitle: 'Assay Test Count Usage',
    apiSource: '/qbr/kpi/assay_usage/timeline',
    apiFolder: 'assay_usage',
    apiChartType: 'BarChart',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/response_time/timeline_view': {
    apiTitle: 'Response Time (Hours)',
    apiSource: '/qbr/kpi/response_time/timeline',
    apiFolder: 'response_time',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/alcm/list_view': {
    apiTitle: 'AbbottLink Customer Maintenance',
    apiSource: '/qbr/kpi/alcm/details',
    table: {
      columns: [
        { ref: 'COUNTRY', description: 'COUNTRY', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'ABBOTTLINK_INSTALL_DATE', description: 'ABBOTTLINK INSTALL DATE', width: 100, hidden: true },
        { ref: 'LAST_CONTACT_DATE', description: 'LAST CONTACT DAYS', width: 100, hidden: true },
        { ref: 'DAILY_SCORE', description: 'DAILY SCORE', width: 100, hidden: true },
        { ref: 'WEEKLY_SCORE', description: 'WEEKLY SCORE', width: 100, hidden: true },
        { ref: 'MONTHLY_SCORE', description: 'MONTHLY SCORE', width: 100, hidden: true },
        { ref: 'QUARTERLY_SCORE', description: 'QUARTERLY SCORE', width: 100, hidden: true },
        { ref: 'OVERALL_SCORE', description: 'OVERALL SCORE', width: 100, hidden: true }
      ]
    }
  },
  '/businessReview/calls_without_service_number/list_view': {
    apiTitle: 'Calls w/Out Service Number',
    apiSource: '/qbr/kpi/calls_without_service_number/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'COUNTRY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'AGENT_NAME', description: 'AGENT NAME', width: 100, hidden: true },
        { ref: 'AGENT_EMAIL', description: 'AGENT EMAIL', width: 100, hidden: true },
        { ref: 'CALL_ID', description: 'CALL ID', width: 100, hidden: true },
        { ref: 'CALL_TYPE', description: 'CALL TYPE', width: 100, hidden: true },
        { ref: 'CALL_TIME_SEC', description: 'CALL TIME (SEC)', width: 100, hidden: true },
        { ref: 'DISPOSITION', description: 'DISPOSITION', width: 100, hidden: true },
        { ref: 'CALL_DATE', description: 'CALL DATE', width: 100, hidden: true }
      ]
    }
  },
  '/businessReview/abandoned_calls/list_view': {
    apiTitle: 'Abandoned Calls',
    apiSource: '/qbr/kpi/abandoned_calls/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'COUNTRY', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'CALL PERIOD', width: 100, hidden: true },
        { ref: 'AGENT_NAME', description: 'AGENT NAME', width: 100, hidden: true },
        { ref: 'AGENT_EMAIL', description: 'AGENT EMAIL', width: 100, hidden: true },
        { ref: 'CALL_ID', description: 'CALL ID', width: 100, hidden: true },
        { ref: 'CALL_TYPE', description: 'CALL TYPE', width: 100, hidden: true },
        { ref: 'CALL_TIME_SEC', description: 'CALL TIME (SEC)', width: 100, hidden: true },
        { ref: 'DISPOSITION', description: 'DISPOSITION', width: 100, hidden: true },
        { ref: 'CALL_DATE', description: 'CALL DATE', width: 100, hidden: true }
      ]
    }
  },
  '/businessReview/average_wait_time/system_comparison_view': {
    apiTitle: 'Average & Longest Wait Times',
    apiSource: '/qbr/kpi/average_wait_time/system_comparison',
    apiFolder: 'average_wait_time',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'CALLS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/average_wait_time/timeline_view': {

    apiTitle: 'Average & Longest Wait Times',
    apiSource: '/qbr/kpi/average_wait_time/timeline',
    apiFolder: 'average_wait_time',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/average_wait_time/list_view': {
    apiTitle: 'Average & Longest Wait Times',
    apiSource: '/qbr/kpi/average_wait_time/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'COUNTRY', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'CALL PERIOD', width: 100, hidden: true },
        { ref: 'AGENT_NAME', description: 'AGENT NAME', width: 100, hidden: true },
        { ref: 'AGENT_EMAIL', description: 'AGENT EMAIL', width: 100, hidden: true },
        { ref: 'CALL_ID', description: 'CALL ID', width: 100, hidden: true },
        { ref: 'CALL_TYPE', description: 'CALL TYPE', width: 100, hidden: true },
        { ref: 'CALL_TIME_SEC', description: 'CALL TIME (SEC)', width: 100, hidden: true },
        { ref: 'WAIT_TIME', description: 'WAIT TIME', width: 100, hidden: true },
        { ref: 'CALL_DATE', description: 'CALL DATE', width: 100, hidden: true }
      ]
    }
  },
  '/businessReview/service_level/list_view': {
    apiTitle: 'Telephone Service Level',
    apiSource: '/qbr/kpi/service_level/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'COUNTRY', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'CALL PERIOD', width: 100, hidden: true },
        { ref: 'AGENT_NAME', description: 'AGENT NAME', width: 100, hidden: true },
        { ref: 'AGENT_EMAIL', description: 'AGENT EMAIL', width: 100, hidden: true },
        { ref: 'CALL_ID', description: 'CALL ID', width: 100, hidden: true },
        { ref: 'CALL_TYPE', description: 'CALL TYPE', width: 100, hidden: true },
        { ref: 'CALL_TIME_SEC', description: 'CALL TIME (SEC)', width: 100, hidden: true },
        { ref: 'DISPOSITION', description: 'DISPOSITION', width: 100, hidden: true },
        { ref: 'CALL_DATE', description: 'CALL DATE', width: 100, hidden: true }
      ]
    }
  },
  '/businessReview/tcr/list_view': {
    apiTitle: 'Test Completion Rate',
    apiSource: '/qbr/kpi/tcr/details',
    table: {
      columns: [
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'CUSTOMER_NUM', description: 'CUSTOMER NUMBER', width: 100, hidden: true },
        { ref: 'COUNTRY', description: 'COUNTRY', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'ABBOTTLINK_INSTALL_DATE', description: 'ABBOTTLINK INSTALL DATE', width: 100, hidden: true },
        { ref: 'LAST_CONTACT_DAYS', description: 'LAST CONTACT DAYS', width: 100, hidden: true },
        { ref: 'DAILY_SCORE', description: 'DAILY SCORE', width: 100, hidden: true },
        { ref: 'WEEKLY_SCORE', description: 'WEEKLY SCORE', width: 100, hidden: true },
        { ref: 'MONTHLY_SCORE', description: 'MONTHLY SCORE', width: 100, hidden: true },
        { ref: 'QUARTERLY_SCORE', description: 'QUARTERLY SCORE', width: 100, hidden: true },
        { ref: 'OVERALL_SCORE', description: 'OVERALL SCORE', width: 100, hidden: true }
      ]
    }
  },
  '/businessReview/example_data/system_comparison_view': {
    apiTitle: 'EXAMPLE DATA',
    apiSource: '/qbr/kpi/example_data/system_comparison',
    apiFolder: 'example_data',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'DATA POINT', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/service_level/system_comparison_view': {
    apiTitle: 'Telephone Service Level',
    apiSource: '/qbr/kpi/service_level/system_comparison',
    apiFolder: 'service_level',
    apiDrillDown: 'timeline_view',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'live_connect_percent', description: 'DATA POINT', width: 100, hidden: true },
        { ref: 'percent_calls', description: 'DATA POINT', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/service_level/timeline_view': {
    apiTitle: 'Telephone Service Level',
    apiSource: '/qbr/kpi/service_level/timeline',
    apiFolder: 'service_level',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/alcm/system_comparison_view': {
    apiTitle: 'ABBOTTLINK CUSTOMER MAINTENANCE',
    apiSource: '/qbr/kpi/alcm/system_comparison',
    apiFolder: 'alcm',
    apiDrillDown: 'list_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'ALCM SCORE(S)', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/instrument_test_counts/system_comparison_view': {
    apiTitle: 'Annualualized Instrument Test Counts',
    apiSource: '/qbr/kpi/instrument_test_counts/system_comparison',
    apiFolder: 'instrument_test_counts',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'AVG INSTRUMENT TEST COUNTS', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/assay_test_counts/system_comparison_view': {
    apiTitle: 'Top Assays',
    apiSource: '/qbr/kpi/assay_test_counts/system_comparison',
    apiFolder: 'assay_test_counts',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'AVG INSTRUMENT TEST COUNTS', width: 100, hidden: true },
      ]
    }
  },

  '/businessReview/assay_usage/system_comparison_view': {
    apiTitle: 'Assay Test Count Usage',
    apiSource: '/qbr/kpi/assay_usage/system_comparison',
    apiFolder: 'assay_usage',
    apiDrillDown: 'timeline_view',
    apiChartType: '',
    table: {
      columns: [ ]
    }
  },

  '/businessReview/response_time/system_comparison_view': {
    apiTitle: 'Response Time (Hours)',
    apiSource: '/qbr/kpi/response_time/system_comparison',
    apiFolder: 'response_time',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'RESPONSE TIME (HRS)', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/calls_without_service_number/system_comparison_view': {
    apiTitle: 'Calls w/Out Service Number',
    apiSource: '/qbr/kpi/calls_without_service_number/system_comparison',
    apiFolder: 'calls_without_service_number',
    apiDrillDown: 'timeline_view',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'CALLS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/calls_without_service_number/timeline_view': {
    apiTitle: 'Calls w/Out Service Number',
    apiSource: '/qbr/kpi/calls_without_service_number/timeline',
    apiFolder: 'calls_without_service_number',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/abandoned_calls/system_comparison_view': {
    apiTitle: 'Abandoned Calls',
    apiSource: '/qbr/kpi/abandoned_calls/system_comparison',
    apiFolder: 'abandoned_calls',
    apiDrillDown: 'timeline_view',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'CALLS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/abandoned_calls/timeline_view': {
    apiTitle: 'Abandoned Calls',
    apiSource: '/qbr/kpi/abandoned_calls/timeline',
    apiFolder: 'abandoned_calls',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/pm_tickets/system_comparison_view': {
    apiTitle: 'Total PM Tickets',
    apiSource: '/qbr/kpi/pm_tickets/system_comparison',
    apiFolder: 'pm_tickets',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'PM TICKETS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/tcr/system_comparison_view': {
    apiTitle: 'Test Completion Rate',
    apiSource: '/qbr/kpi/tcr/system_comparison',
    apiFolder: 'tcr',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'TEST COMPLETION RATE', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/tcr/timeline_view': {
    apiTitle: 'Test Completion Rate',
    apiSource: '/qbr/kpi/tcr/timeline',
    apiFolder: 'tcr',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/pm_tickets/timeline_view': {
    apiTitle: 'PM Tickets',
    apiSource: '/qbr/kpi/pm_tickets/timeline',
    apiFolder: 'pm_tickets',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/total_inbound_calls/system_comparison_view': {
    apiTitle: 'Total Inbound Calls',
    apiSource: '/qbr/kpi/total_inbound_calls/system_comparison',
    apiFolder: 'total_inbound_calls',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'CALLS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/total_inbound_calls/timeline_view': {
    apiTitle: 'Total Inbound Calls',
    apiSource: '/qbr/kpi/total_inbound_calls/timeline',
    apiFolder: 'total_inbound_calls',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/total_inbound_calls/list_view': {
    apiTitle: 'Total Inbound Calls',
    apiSource: '/qbr/kpi/total_inbound_calls/details',
    table: {
      columns: [
        { ref: 'COUNTRY', description: 'LOCATION', width: 100, hidden: true },
        { ref: 'LOCALLEVEL1', description: 'LOCAL LEVEL 1', width: 100, hidden: true },
        { ref: 'LOCALLEVEL2', description: 'LOCAL LEVEL 2', width: 100, hidden: true },
        { ref: 'CITY', description: 'CITY', width: 100, hidden: true },
        { ref: 'SN', description: 'SERIAL NUMBER', width: 100, hidden: true },
        { ref: 'PL', description: 'PRODUCT LINE', width: 100, hidden: true },
        { ref: 'PERIOD', description: 'REPORT PERIOD', width: 100, hidden: true },
        { ref: 'CUSTOMER', description: 'CUSTOMER', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/mttr/system_comparison_view': {
    apiTitle: 'MTTR (Hours)',
    apiSource: '/qbr/kpi/mttr/system_comparison',
    apiFolder: 'mttr',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'MTTR (HRS)', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/mttr/timeline_view': {
    apiTitle: 'MTTR (Hours)',
    apiSource: '/qbr/kpi/mttr/timeline',
    apiFolder: 'mttr',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/ticket_history/system_comparison_view': {
    apiTitle: 'Ticket History',
    apiSource: '/qbr/kpi/ticket_history/system_comparison',
    apiFolder: 'ticket_history',
    apiDrillDown: 'timeline_view',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'remote', description: 'REMOTE', width: 100, hidden: true },
        { ref: 'fsv', description: 'FIELD SERVICE VISIT', width: 100, hidden: true },
        { ref: 'complaints', description: 'COMPLAINTS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/ticket_history/timeline_view': {
    apiTitle: 'Ticket History',
    apiSource: '/qbr/kpi/ticket_history/timeline',
    apiFolder: 'ticket_history',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/phn/system_comparison_view': {
    apiTitle: 'PHN Tickets',
    apiSource: '/qbr/kpi/phn/system_comparison',
    apiFolder: 'phn',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'TICKETS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/phn/timeline_view': {
    apiTitle: 'PHN Tickets',
    apiSource: '/qbr/kpi/phn/timeline',
    apiFolder: 'phn',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/ticket_erros/system_comparison_view': {
    apiTitle: 'PHN Tickets',
    apiSource: '/qbr/kpi/ticket_erros/system_comparison',
    apiFolder: 'ticket_erros',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'TICKETS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/tsb/system_comparison_view': {
    apiTitle: 'TSBs',
    apiSource: '/qbr/kpi/tsb/system_comparison',
    apiFolder: 'tsb',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'TICKETS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/tsb/timeline_view': {
    apiTitle: 'TSBs',
    apiSource: '/qbr/kpi/tsb/timeline',
    apiFolder: 'tsb',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/uptime/system_comparison_view': {
    apiTitle: 'Uptime',
    apiSource: '/qbr/kpi/uptime/system_comparison',
    apiFolder: 'uptime',
    apiDrillDown: 'timeline_view',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'TICKETS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/uptime/timeline_view': {
    apiTitle: 'Uptime',
    apiSource: '/qbr/kpi/uptime/timeline',
    apiFolder: 'uptime',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/hard_stops/system_comparison_view': {
    apiTitle: 'Hard Stops',
    apiSource: '/qbr/kpi/hard_stops/system_comparison',
    apiFolder: 'hard_stops',
    apiDrillDown: 'timeline_view',
    apiChartType: 'MultiBar',
    table: {
      columns: [
        { ref: 'customer', description: 'CUSTOMER', width: 100, hidden: true },
        { ref: 'values', description: 'HARD STOPS', width: 100, hidden: true },
      ]
    }
  },
  '/businessReview/hard_stops/timeline_view': {
    apiTitle: 'Hard Stops',
    apiSource: '/qbr/kpi/hard_stops/timeline',
    apiFolder: 'hard_stops',
    apiChartType: 'StackBar',
    table: {
      columns: [
        { ref: 'M1', description: '2022-05', width: 100, hidden: true },
        { ref: 'M2', description: '2022-06', width: 100, hidden: true },
        { ref: 'M3', description: '2022-07', width: 100, hidden: true },
        { ref: 'M4', description: '2022-08', width: 100, hidden: true },
      ]
    }
  }
};
