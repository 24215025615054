<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
  <div style="text-align:center;">
    <style>
      td.npsStyle {
        margin-bottom: 5px;
        border-bottom-width: 2px !important;
        border-bottom-color: black !important;
        padding-bottom: 2px !important;
      }
    </style>
    <h3>{{title}}</h3>
    <div *ngIf="isDataFound(apiData)">
      <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
        <table style="color:black;font-size:18px;width:90%;">
          <tr>
            <td class="npsStyle" style="border-bottom-width: 2px; border-bottom-color: black;">Hourly Test Count Average</td>
            <td class="npsStyle" style="text-align: right;font-weight: bold;color: {{hourColor}};">{{hourValue}}</td>
          </tr>
          <tr>
            <td colspan="2" style="height:2px;"><hr width="80%" /></td>
          </tr>
          <tr>
            <td class="npsStyle" style="border-bottom-width: 2px; border-bottom-color: black;">Daily Test Count Average</td>
            <td class="npsStyle" style="text-align:right;font-weight:bold;color:{{dayColor}};">{{dayValue}}</td>
          </tr>
          <tr>
            <td colspan="2" style="height:2px;"><hr width="80%" /></td>
          </tr>
          <tr>
            <td class="npsStyle" style="border-bottom-width: 2px; border-bottom-color: black;">Instrument Census</td>
            <td class="npsStyle" style="text-align: right;font-weight: bold;color: {{censusColor}};">{{censusValue}}</td>
          </tr>
        </table>
      </a>
    </div>
  </div>
</app-state-handler>
