<div class="ct-section-metrics-title">
    Customer Health Metrics
</div>
<app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData !== null">
        <!--<div class="legend-container">-->
            <!--<div class="legend" *ngIf="colorLegend !== null">-->
                <!--<div [class]="colorLegend.title.format">{{colorLegend.title.label}}</div>-->
                <!--<div *ngFor="let item of colorLegend.items" class="legend-item" [style.color]="item.color" [style.background-color]="item.bgcolor">-->
                    <!--{{item.label}}&lt;!&ndash;{{item.count}}&ndash;&gt;&lt;!&ndash;{{item.pct}}&ndash;&gt;-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="legend" *ngIf="npsLegend !== null">-->
                <!--<div [class]="npsLegend.title.format">{{npsLegend.title.label}}</div>-->
                <!--<div *ngFor="let item of npsLegend.items" class="legend-item" [style.color]="item.color" [style.background-color]="item.bgcolor">-->
                    <!--{{item.label}}&lt;!&ndash;{{item.count}}&ndash;&gt;&lt;!&ndash;{{item.pct}}&ndash;&gt;-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <kendo-dialog
                [title]="helpWindowTitle"
                *ngIf="opened"
                (close)="close()"
                [width]="'80%'"
                [height]="'80%'"
                [actions]="helpActions"
                [actionsLayout]="actionsLayout"
                (action)="onAction($event)"
        >
            <div class="tooltip2" *ngIf="tooltips.overAllHealthScore">
                <p>Overall health is a customer-level aggregate score
                    to indicate the operational health of a customer site.
                    The Overall Health Score is computed by the AlwaysOn Health algorithm and incorporates
                    instrument operational and customer satisfaction metrics.
                    The algorithm scores each customer SN on each instrument metric in relation to other
                    customer's SNs in the same geographic area. Scores are then rolled up at the customer
                    level and weighted together with NPS data (if available) to arrive at an Overall health
                    score. Weightings: tNPS (50%), test volume (10%), reactive tickets (10%),
                    test completion (10%), hard stops (5%), controls out of range (5%), repeat tests (5%),
                    daily maintenance failures (5%). Where data for a metric is unavailable,
                    weightings are uniformly redistributed between available metrics.
                    Color indicates customer health as
                    <span class="legend-item" style="background-color: #52B84D;">good (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">satisfactory (yellow)</span>,
                    <span class="legend-item" style="background-color: orange;">needs improvement (orange)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    If Overall Health cannot be calculated due to insufficient data,
                    no score is provided and coloring is set to gray.
                </p>
                <!--<p>Note that the color coding of each metric reflects the aggregate of all of a-->
                    <!--customer's SNs and should be interpreted as the average health of a customers-->
                    <!--instruments.</p>-->
                <!--<div class="legend" *ngIf="colorLegend !== null">-->
                    <!--<div [class]="colorLegend.title.format">{{colorLegend.title.label}}</div>-->
                    <!--<div *ngFor="let item of colorLegend.items" class="legend-item" [style.color]="item.color" [style.background-color]="item.bgcolor">-->
                        <!--{{item.label}}&lt;!&ndash;{{item.count}}&ndash;&gt;&lt;!&ndash;{{item.pct}}&ndash;&gt;-->
                    <!--</div>-->
                <!--</div>-->
            </div>
            <div class="tooltip2" *ngIf="tooltips.tnpsRawScore">
                <p>Survey responses to the Likelihood to Recommend question
                    ("On a scale of 0-10, how likely are you to recommend Abbott to a
                    colleague or friend?") on the Transactional NPS (tNPS) survey for
                    a customer site over the past 1 year. Scores are averaged for a customer site
                    if data from more than one interview is available. Color indicates a customer
                    is a
                    <span class="legend-item" style="background-color: #52B84D;">promoter (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">passive (yellow)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">detractor (red)</span></p>
                <!--<p>Colors indicate how a customer falls on a given metric compared to other customers-->
                    <!--in the same area.</p>-->
                <!--<p>Note that the color coding of each metric reflects the aggregate of all of a-->
                    <!--customer's SNs and should be interpreted as the average health of a customers-->
                    <!--instruments.</p>-->
                <!--<div class="legend" *ngIf="npsLegend !== null">-->
                    <!--<div [class]="npsLegend.title.format">{{npsLegend.title.label}}</div>-->
                    <!--<div *ngFor="let item of npsLegend.items" class="legend-item" [style.color]="item.color" [style.background-color]="item.bgcolor">-->
                        <!--{{item.label}}&lt;!&ndash;{{item.count}}&ndash;&gt;&lt;!&ndash;{{item.pct}}&ndash;&gt;-->
                    <!--</div>-->
                <!--</div>-->
            </div>
            <div class="tooltip2" *ngIf="tooltips.snCountIA">
                <p>Number of customer IA instruments. For Alinity, this is the number of IA serial numbers
                    that are test-of-record.</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.snCountCC">
                <p>Number of customer CC instruments. For Alinity, this is the number of CC serial numbers
                    that are test-of-record.</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.testVolumeIA">
                <p>The average weekly test volume for all customer IA instruments. Color coding indicates
                    how a customer’s level of IA test volume was scored by the AlwaysOn Customer Health algorithm:
                    <span class="legend-item" style="background-color: #52B84D">good (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">satisfactory (yellow)</span>,
                    <span class="legend-item" style="background-color: orange;">needs improvement (orange)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    Because each customer site has a unique usage pattern and varying combinations of
                    instruments, color code scoring is unique to each customer site and is not comparable
                    to other customers (e.g., an IA test volume value color coded as good for one customer
                    may be only satisfactory for another).</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.testVolumeCC">
                <p>The average weekly test volume for all customer CC instruments. Color coding indicates how
                    a customer’s level of CC test volume was scored by the AlwaysOn Customer Health algorithm:
                    <span class="legend-item" style="background-color: #52B84D">good (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">satisfactory (yellow)</span>,
                    <span class="legend-item" style="background-color: orange;">needs improvement (orange)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    Because each customer site has a unique usage pattern and varying combinations of
                    instruments, color code scoring is unique to each customer site and is not comparable
                    to other customers (e.g., a CC test volume value color coded as good for one customer
                    may be only satisfactory for another).</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.testCompletionPercent">
                <p>Average weekly percentage of completed tests for all of a customer’s instruments
                    (aggregates both IA and CC product lines). Color coding indicates how a customer’s level
                    of test completion was scored by the AlwaysOn Customer Health algorithm:
                    <span class="legend-item" style="background-color: #52B84D">good (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">satisfactory (yellow)</span>,
                    <span class="legend-item" style="background-color: orange;">needs improvement (orange)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    Because each customer site has a unique usage pattern and varying combinations of
                    instruments, color code scoring is unique to each customer site and is not comparable to
                    other customers (e.g., a test completion value color coded as good for one customer may be
                    only satisfactory for another).</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.hardStopCount">
                <p>Average weekly number of hard stops across all of a customer's Alinity instruments
                    (aggregates both IA and CC product lines). Color coding indicates how the number
                    of hard stops at a customer site was scored by the AlwaysOn Customer Health algorithm:
                    <span class="legend-item" style="background-color: #52B84D">good (green)</span>,
                    <span class="legend-item" style="background-color: orange;">satisfactory (orange)</span>,
                    <span class="legend-item" style="background-color: yellow;">needs improvement (yellow)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    Because each customer site has a unique usage pattern and varying combinations of
                    instruments, color code scoring is unique to each customer site and is not comparable
                    to other customers (e.g., a hard stop value color coded as good for one customer may be only
                    satisfactory for another). The Hard Stop metric is available only for Alinity.</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.qcFailPercent">
                <p>Average weekly percentage of controls that fall outside of customer-defined boundaries
                    (includes both IA and CC product lines). Color coding indicates how a customer’s level
                    of test completion was scored by the AlwaysOn Customer Health algorithm:
                    <span class="legend-item" style="background-color: #52B84D">good (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">satisfactory (yellow)</span>,
                    <span class="legend-item" style="background-color: orange;">needs improvement (orange)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    Because each customer site has a unique usage pattern and varying combinations of instruments,
                    color code scoring is unique to each customer site and is not comparable to other customers
                    (e.g., a controls out of range value color coded as good for one customer may be only
                    satisfactory for another).</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.retestPercent">
                <p>Average weekly percentage of repeat tests across all of a customer's instruments
                    (includes both IA and CC product lines). Repeat tests are specimens that produced
                    a result two or more times for the same assay, on the same instrument, indicating
                    that the customer chose to rerun a specimen that already generated a result.
                    Color coding indicates how a customer’s level of test completion was scored by the
                    AlwaysOn Customer Health algorithm:
                    <span class="legend-item" style="background-color: #52B84D">good (green)</span>,
                    <span class="legend-item" style="background-color: yellow;">satisfactory (yellow)</span>,
                    <span class="legend-item" style="background-color: orange;">needs improvement (orange)</span>, or
                    <span class="legend-item" style="background-color: #FF6347; color: white;">poor (red)</span>.
                    Because each customer site has a unique usage pattern and varying combinations
                    of instruments, color code scoring is unique to each customer site and is not
                    comparable to other customers (e.g., a controls out of range value color coded as
                    good for one customer may be only satisfactory for another).</p>
            </div>
            <div class="tooltip2" *ngIf="tooltips.openTicketCount">
                <p>Number of currently open tickets for a given customer (excluding TSBs).</p>
            </div>
        </kendo-dialog>
        <kendo-grid
            #grid="kendoGrid"
            [data]="gridData"
            [pageable]="true"
            [pageSize]="gridState.take"
            [skip]="gridState.skip"
            [filter]="gridState.filter"
            filterable="menu"
            [sort]="gridState.sort"
            [sortable]="true"
            [resizable]="true"
            (pageChange)="pageChange($event)"
            (sortChange)="sortChange($event)"
            (filterChange)="filterChange($event)"
            style="max-height: 400px"
        >
            <!--scrollable="none"-->
            <kendo-grid-excel [fileName]="'grid'" [fetchData]="allData">
                <ng-container *ngFor="let col of dataCols">
                    <kendo-excelexport-column [field]="col.ref+'.value'" [title]="col.description" *ngIf="col.export"></kendo-excelexport-column>
                </ng-container>
            </kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel" style="position: absolute;margin-top: -31px;z-index: 1;">Export to Excel</button>
                <!--&nbsp;|-->
                <!--<kendo-buttongroup [selection]="'single'">-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('all')" [selected]="true">*</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('tcr')">Test Completion Rate</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('nps')">NPS</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('alcm')">ALCM</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('tor')">TOR</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('tv')">Test Volume</button>-->
                <!--</kendo-buttongroup>-->
                <!--<kendo-buttongroup [selection]="'single'">-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('ia_cc')" [selected]="true">*</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('ia')">IA</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('cc')">CC</button>-->
                <!--</kendo-buttongroup>-->
                <!--<kendo-buttongroup [selection]="'single'">-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('alinity_architect')" [selected]="true">*</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('alinity')">Alinity</button>-->
                    <!--<button kendoButton [toggleable]="true" (click)="setColumns('architect')">Architect</button>-->
                <!--</kendo-buttongroup>-->
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                        [field]="col.ref+'.value'"
                        [title]="col.description"
                        [width]="col.width"
                        [hidden]="col.hidden"
                        [filterable]="col.filterable"
                        [headerStyle]="{'background-color': '#7CCC6C','color': '#fff','line-height': '1em','font-weight': 'bold'}">
                    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                        {{column.title}}
                        <span *ngIf="column.field === 'overAllHealthScore.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('overAllHealthScore')"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'tnpsRawScore.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('tnpsRawScore', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'snCountIA.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('snCountIA', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'snCountCC.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('snCountCC', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'testVolumeIA.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('testVolumeIA', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'testVolumeCC.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('testVolumeCC', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'testCompletionPercent.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('testCompletionPercent', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'hardStopScaled.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('hardStopCount', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'qcFailPercent.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('qcFailPercent', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'retestPercent.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('retestPercent', true)"></fa-icon>
                        </span>
                        <span *ngIf="column.field === 'openTicketCount.value'">
                            <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('openTicketCount', true)"></fa-icon>
                        </span>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="whole-cell"
                            [class.right]="['overAllHealthScore','tnpsRawScore','snCountIA','snCountCC','testVolumeIA','testVolumeCC','testCompletionPercent','hardStopCount','hardStopScaled','qcFailPercent','retestPercent','openTicketCount'].includes(col.ref)"
                            [style.color]
                                      ="dataItem[col.ref].color"
                            [style.background-color]="dataItem[col.ref].bgcolor"
                        >
                            <span *ngIf="col.ref === 'customerNum'">
                                <a class="customer-select" (click)="selectCustomer(dataItem)">{{dataItem[col.ref].value}}</a>
                            </span>
                            <span *ngIf="col.ref === 'customerName'">
                                {{dataItem[col.ref].value}}<br/>
                                <span class="account-info">{{dataItem.acctEntityName.value}}</span>
                            </span>
                            <span *ngIf="['overAllHealthScore'].includes(col.ref)">
                                {{dataItem[col.ref].value|percent}}
                            </span>
                            <span *ngIf="['testCompletionPercent'].includes(col.ref)">
                                {{dataItem[col.ref].value|percent:'1.1-1'}}
                            </span>
                            <span *ngIf="['qcFailPercent','retestPercent'].includes(col.ref)">
                                {{dataItem[col.ref].value|percent:'1.2-2'}}
                            </span>
                            <span *ngIf="['tnpsRawScore'].includes(col.ref)">
                                {{dataItem[col.ref].value|number:'1.2-2'}}
                            </span>
                            <span *ngIf="['hardStopCount', 'hardStopScaled'].includes(col.ref)">
                                {{dataItem[col.ref].value|number:'1.2-2'}}
                            </span>
                            <span *ngIf="!['customerName','customerNum','hardStopCount','hardStopScaled','tnpsRawScore','overAllHealthScore','testCompletionPercent','qcFailPercent','retestPercent'].includes(col.ref)">
                                {{dataItem[col.ref].value}}
                            </span>
                        </span>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
            <!--<kendo-grid-column field="shipDate" title="Ship Date" width="135" filter="date" format="{0:yyyy-MM-dd}">-->
            <!--<ng-template kendoGridCellTemplate let-dataItem>-->
            <!--{{dataItem.shipDate | date:'yyyy-MM-dd' | emptyMask}}-->
            <!--</ng-template>-->
            <!--</kendo-grid-column>-->
        </kendo-grid>
    </div>
</app-state-handler>