<style>
  table.tbClass {
    font-size: 12px;
    width: 100%;
    border: 1px black solid;
  }

  td.tdHeadings {
    text-align: center !important;
    border: 1px black solid;
    font-weight: bold;
    color: white;
  }

  td.tdData {
    text-align: right !important;
    border: 1px black solid;
    padding: 5px;
  }

</style>
<div style="margin-top:-70px;">
  <button [routerLink]="['/businessReview']">KPI Home</button>
  <button *ngIf="this.returnLink !== undefined" [routerLink]="this.returnLink.url" [queryParams]="this.returnLink.params">Return</button>
  <div style="width: 100%;">
    <app-filter-presentation-header></app-filter-presentation-header>
  </div>
  <table class='sales-details-table'>
    <tr>
      <td colspan="2" style="font-weight:bold;font-size:15pt;color:Highlight">{{apiDisplayTitle}} - Network List (New System Comparison View)</td>
    </tr>
  </table>
  <table width="98%" style="align-self:center;"><!--VIEW OF TEST-->
    <tr *ngFor="let chartItem of chartData">
      <td>
        <table width="98%">
        <tr>
          <td colspan="2" style="text-align:center;">
            <a [routerLink]="getDetailLink()" [queryParams]="getQueryParams({'uck':chartItem.uck})">
              <b>{{chartItem.cust}} - {{chartItem.uck}}</b>
            </a><br />
          </td>
        </tr>
        <tr>
          <td width="70%" style="text-align:center;border:solid;"><i>HOURLY TEST COUNTS</i><br />
            <kendo-chart>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="aChartLabelHour">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-series>
                <kendo-chart-series-item type="column" [data]="chartItem.hours">
                  <kendo-chart-series-item-highlight [visible]="false"></kendo-chart-series-item-highlight>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </td> 
          <td width="30%" style="text-align:center;border:solid;"><i>DAILY TEST COUNTS</i><br />
            <kendo-chart>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="aChartLabelDay">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-series>
                <kendo-chart-series-item type="column" [data]="chartItem.days">
                  <kendo-chart-series-item-highlight [visible]="false"></kendo-chart-series-item-highlight>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </td>
        </tr>
        <tr>
          <td style="padding:2px;"><!-- HOURLY TEST COUNT VALUES -->
            <br />
            <table class="tbClass">
              <tr style="background-color:lightseagreen;">
                <td class="tdHeadings">00</td>
                <td class="tdHeadings">01</td>
                <td class="tdHeadings">02</td>
                <td class="tdHeadings">03</td>
                <td class="tdHeadings">04</td>
                <td class="tdHeadings">05</td>
                <td class="tdHeadings">06</td>
                <td class="tdHeadings">07</td>
                <td class="tdHeadings">08</td>
                <td class="tdHeadings">09</td>
                <td class="tdHeadings">10</td>
                <td class="tdHeadings">11</td>
                <td class="tdHeadings">12</td>
                <td class="tdHeadings">13</td>
                <td class="tdHeadings">14</td>
                <td class="tdHeadings">15</td>
                <td class="tdHeadings">16</td>
                <td class="tdHeadings">17</td>
                <td class="tdHeadings">18</td>
                <td class="tdHeadings">19</td>
                <td class="tdHeadings">20</td>
                <td class="tdHeadings">21</td>
                <td class="tdHeadings">22</td>
                <td class="tdHeadings">23</td>
              </tr>
              <tr>
                <td class="tdData" style="width:4%;">{{chartItem.hours[0]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[1]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[2]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[3]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[4]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[5]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[6]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[7]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[8]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[9]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[10]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[11]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[12]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[13]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[14]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[15]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[16]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[17]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[18]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[19]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[20]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[21]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[22]}}</td>
                <td class="tdData" style="width:4%;">{{chartItem.hours[23]}}</td>
              </tr>
            </table>
          </td>
          <td style="padding:2px;"><!-- DRILY TEST COUNT VALUES -->
            <br />
            <table class="tbClass">
              <tr style="background-color:lightseagreen;">
                <td class="tdHeadings">SU</td>
                <td class="tdHeadings">MO</td>
                <td class="tdHeadings">TU</td>
                <td class="tdHeadings">WE</td>
                <td class="tdHeadings">TH</td>
                <td class="tdHeadings">FR</td>
                <td class="tdHeadings">SA</td>
              </tr>
              <tr>
                <td class="tdData">{{chartItem.days[0]}}</td>
                <td class="tdData">{{chartItem.days[1]}}</td>
                <td class="tdData">{{chartItem.days[2]}}</td>
                <td class="tdData">{{chartItem.days[3]}}</td>
                <td class="tdData">{{chartItem.days[4]}}</td>
                <td class="tdData">{{chartItem.days[5]}}</td>
                <td class="tdData">{{chartItem.days[6]}}</td>
              </tr>
            </table>
          </td>
        </tr>
          <tr>
            <td colspan="2"><hr style="width:80%;color:black;"/></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
