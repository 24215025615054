import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileComponent } from '../../base/tile.component';
import { RenderEvent, SeriesVisualArgs } from '@progress/kendo-angular-charts';
import { Rect, Circle as CircleGeometry } from '@progress/kendo-drawing/geometry';
import { Group, Path, Layout, Text } from '@progress/kendo-drawing';

@ComponentLookup('PieChartComponent')
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends TileComponent implements OnInit {

  private goalPercent: number;
  private center: any;
  private radius: number;

  // Necessary to bind `this` to the AppComponent instance
  public visualHandler = this.visual.bind(this);

  translateApiDataForChart(apiData) {
    const pieDataTemp: any[] = [];
    if ('bar_values' in apiData) {
      for (const pieItem of apiData.bar_values) {
        pieDataTemp.push({
          value: pieItem.value,
          dashType: 'solid'
        });
      }
    }
    for (const pieItem of apiData.values) {
      if (this.preferences.showGoal === true && pieItem.slicelabel === this.preferences.goalPercentageSlideLabel) {
        this.goalPercent = pieItem.goalpercentage / 100;
      }
      pieDataTemp.push({
        category: this.preferences.renderForServiceContracts
          ? (pieItem.slicelabel + ' ' + pieItem.percentage + '% (' + pieItem.count + ')') : pieItem.slicelabel,
        value: pieItem.percentage,
        color: pieItem.color,
        dashType: 'solid'
      });
    }
    // console.log(this.goalPercent);

    if (this.preferences.showGoal === true) {
      pieDataTemp.push({ category: 'Goal', value: 0.00, dashType: 'dot', color: 'black' });
    }

    if ('renderForServiceContracts' in this.preferences && this.preferences.renderForServiceContracts === true) {
      pieDataTemp.push({ category: 'Contracts Expiring in < 30 days: ' + apiData.contractexp, value: 0.00 });
      apiData.title = 'Service Contracts (Including RAP)';
      apiData.subtitle = apiData.contractexpNdays + ' expiring in 90 Days';
    }

    this.chartOptions = {
      pieData: pieDataTemp,
      /*legend: {
        position: 'bottom',
        orientation: 'horizontal',
        item: {
          visual: this.legendItemVisual
        }
      },*/
      series: [
        {
          type: 'pie',
          data: pieDataTemp,
          field: 'value',
          categoryField: 'category',
          border: {
            color: '#000',
            width: 1,
            dashType: 'solid'
          }/*,
          tooltip: {
            visible: true,
            format: '{0}%'
          },
          visual: (e: SeriesVisualArgs) => {
            console.log('t2', e);
          }*/
        }
      ]
    };
  }

  public legendItemVisual = (args: any) => {
    const path = new Path({ stroke: {
        color: args.series.data[args.pointIndex].color,
        dashType: args.series.data[args.pointIndex].dashType,
        width: 3
      }}).moveTo(0, 0).lineTo(15, 0).close();
    const text = new Text(args.series.data[args.pointIndex].category, [0, 0]);
    const layout = new Layout(new Rect([0, 0], [Number.MAX_VALUE, 0]), {
      alignItems: 'center',
      spacing: 5
    });

    layout.append(path, text);
    layout.reflow();

    return layout;
  }

  public visual(e: any): Group {
    // Obtain parameters for the segments
    this.center = e.center;
    this.radius = e.radius;

    // console.log("Metric: " + this.apiData.title + ", Center: " + this.center + ", Start Angle: " + e.startAngle
    // + ", End Angle: " + e.endAngle + ", Percentage: " + e.percentage + ", Radius: " + this.radius
    // + ", Goal Percent: " + this.goalPercent);
    // Create default visual
    return e.createVisual();
  }

  public onRender(e: RenderEvent): void {
    // The center and radius are populated by now.
    // We can ask a circle geometry to calculate the bounding rectangle for us.
    //
    // http://www.telerik.com/kendo-angular-ui/components/drawing/api/geometry/Circle/

    if (this.preferences.showGoal === false) {
      return;
    }
    const c = this.center;
    // console.log("Metric: " + this.apiData.title + ", Center: " + c + ", radius: " + this.radius);
    const circleGeometry = new CircleGeometry([c.x, c.y], this.radius);
    // Reflow the text in the bounding box
    //
    // http://www.telerik.com/kendo-angular-ui/components/drawing/api/Layout
    // http://www.telerik.com/kendo-angular-ui/components/drawing/api/LayoutOptions
    const path = new Path({
        stroke: {
          color: '#000',
          dashType: 'dash',
          width: 1
        }
    });

    // The starting point (or when 0 is passed) is at: 0.25
    // 1 degree equals 1/360; starting point center-right
    const calcGoalPercent = this.goalPercent - 0.25; // 0.90 - 0.25 to make starting point relative to top-center

    const point = circleGeometry.pointAt(360 * calcGoalPercent);

    path.moveTo(c.x, c.y).lineTo(point.x, point.y);

    /*let point1 = circleGeometry.pointAt(441.3);   //circleGeometry.pointAt(360*this.goalPercent);

    const angle = 360 * this.goalPercent;

    const pointx = c.x + this.radius * Math.cos(angle);
    const pointy = c.y + this.radius * Math.sin(angle);

    console.log("Metric: " + this.apiData.title + ", angle: " + angle + ", circle point1: " + pointx + ", " + pointy);
    let point = circleGeometry.pointAt(-120);   //circleGeometry.pointAt(360*this.goalPercent);
    console.log("Metric: " + this.apiData.title + ", circle point: " + point.x + ", " + point.y);
    path.moveTo(c.x, c.y).lineTo(pointx, pointy); */

    // layout.append(heading, line1, line2);
    // path.reflow();

    // Draw it on the Chart drawing surface
    // http://www.telerik.com/kendo-angular-ui/components/charts/api/ChartComponent/#toc-surface
    e.sender.surface.draw(path);
  }
}
