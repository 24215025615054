import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { BrParamsService } from '../../br-params.service';
import { BrHistoryRange } from '../../br-history-range.service';
import { NavbarBuilderService } from '../../../navigation/navbar-builder.service';
import { ToolSelectionOptions } from '../../../configuration/nav-filter-selection';
import { ListViewPages } from '../../configuration/details/list-view-pages';
import { RequestState } from '../../../api/request-handler';
import { ApiService } from '../../../api/api.service';
import { RefreshHandlerService } from '../../../api/refresh-handler.service';
import { GridComponent } from '../../../details/grid.component';
import { DetailQueryParamsService } from '../../detail-query-params.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-br-system-comparison-chart-view',
  templateUrl: './br-system-comparison-chart-view.component.html',
  styleUrls: ['./br-system-comparison-chart-view.component.scss']
})


export class BrSystemComparisonChartViewComponent extends GridComponent implements OnInit, OnDestroy {

  public apiData;
  public apiMultiData;
  public state = RequestState.Inactive;

  apiFolder;
  apiDrillDown;
  apiDisplayTitle;
  requestHandler;
  stateChangeHandler;
  detailQueryCompleteSubscription;
  returnLinkSubscription;
  queryParamsSubscription;
  routeUrlSubscription;
  returnLink;
  kpiConfig;
  listViewPages = ListViewPages;
  apiUrl;

  aChartLabelHour = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  aChartLabelDay = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  dataColsHours = [
    { ref: '00', description: '00' },
    { ref: '01', description: '01' }, 
    { ref: '02', description: '02' },
    { ref: '03', description: '03' },
    { ref: '04', description: '04' },
    { ref: '05', description: '05' },
    { ref: '06', description: '06' },
    { ref: '07', description: '07' },
    { ref: '08', description: '08' },
    { ref: '09', description: '09' },
    { ref: '10', description: '10' },
    { ref: '11', description: '11' },
    { ref: '12', description: '12' },
    { ref: '13', description: '13' },
    { ref: '14', description: '14' },
    { ref: '15', description: '15' },
    { ref: '16', description: '16' },
    { ref: '17', description: '18' },
    { ref: '19', description: '19' },
    { ref: '20', description: '20' },
    { ref: '21', description: '21' },
    { ref: '22', description: '22' },
    { ref: '23', description: '23' },
  ];
  dataColsDays = [
    { ref: 'SU', description: 'SU' },
    { ref: 'MO', description: 'MO' },
    { ref: 'TU', description: 'TU' },
    { ref: 'WE', description: 'WE' },
    { ref: 'TH', description: 'TH' },
    { ref: 'FR', description: 'FR' },
    { ref: 'SA', description: 'SA' }
  ];
  dataRowsHours = [];
  dataRowsDays = [];
  multiDataRows = [];
  chartData = [];
  multiChartData = [];
  chartRowCount = 0;
  chartOptions;
  chartBottomLimit;
  chartTopLimit;

  sCustomerName;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private brParamsService: BrParamsService,
    private brHistoryRange: BrHistoryRange,
    private location: Location,
    private apiService: ApiService,
    private detailQueryParamsService: DetailQueryParamsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    this.detailQueryCompleteSubscription = this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.listViewPages.hasOwnProperty(currentRouterLocation)) {
        this.kpiConfig = this.listViewPages[currentRouterLocation];
      }

      this.apiUrl = this.kpiConfig.apiSource;
      this.apiDisplayTitle = this.kpiConfig.apiTitle;
      this.apiFolder = this.kpiConfig.apiFolder;
      if (this.kpiConfig.apiDrillDown) { this.apiDrillDown = this.kpiConfig.apiDrillDown; }
      // console.log('API CONFIGS', this.kpiConfig);

      this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_detail'));

      this.navbarBuilderService.setNavbarChoice('business-review');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        dashboardSelector: false,
        toolSelection: ToolSelectionOptions.businessreview,
        bookmarksFlag: false,
        viewTitle: false,
        viewTitleText: '',
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      this.requestHandler = this.apiService.get(this.apiUrl);
      this.requestHandler.sendRequest(this.apiUrl);

      // no need to send request here as chart tile has already done so
      console.log('LINE NUMBER: 111');
      this.stateChangeHandler = (state) => {
        this.state = state;
        if (state === RequestState.Complete) {

          console.log('LINE NUMBER: 116');
          const response = this.requestHandler.getResponse();
          this.apiData = JSON.parse(response.body);
          // console.log('TIMELINE DATA', this.apiData.values);

          // FORMAT DATA ROWS FOR KENDO COMPONENT
          this.dataRowsHours = [];
          this.dataRowsDays = [];
          this.chartData = [];
          
          //console.log('LINE NUMBER: 170', this.apiData.values.length);
          for (let j = 0; j < this.apiData.values.length; j++) {

            this.chartData[j] = [];
            this.chartData[j].cust = this.apiData.values[j].customer;
            this.chartData[j].uck = this.apiData.values[j].uck;
            this.chartData[j].hours = [];
            const apiDataRow = this.apiData.values[j].hour;
            const dataRowHours = {};            

            for (let c = 0; c < this.dataColsHours.length; c++) {
              const dataColConfig = this.dataColsHours[c];

              dataRowHours[dataColConfig.ref] = {
                value: apiDataRow[dataColConfig.ref]
              };
                                
              this.chartData[j].hours.push(dataRowHours[dataColConfig.ref].value);  
            }
            
            // LOOP THRU AND GET THE DAY VALUES.....
            this.chartData[j].days = [];
            const apiDataRowDays = this.apiData.values[j].day;
            const dataRowDays = {};

            for (let d = 0; d < this.dataColsDays.length; d++) {
              const dataColConfigDay = this.dataColsDays[d];

              dataRowDays[dataColConfigDay.ref] = {
                value: apiDataRowDays[dataColConfigDay.ref]
              };
                                
              this.chartData[j].days.push(dataRowDays[dataColConfigDay.ref].value);  
            } 
          }
          //console.log('LINE NUMBER: 220', this.chartData);

          this.updateGrid();
        }
      };
      this.requestHandler.onStateChange(this.stateChangeHandler);
      this.updateGrid();
    });

    this.returnLinkSubscription = this.detailQueryParamsService.getReturnLink().subscribe((pathDetails) => {
      this.returnLink = pathDetails;
    });

    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.detailQueryParamsService.prepReturnParams(params);
      this.detailQueryParamsService.buildPersistedValuesFromParams(params);
    });

    this.routeUrlSubscription = this.route.url.subscribe((url) => {
      this.detailQueryParamsService.prepReturnLink(url);
      this.detailQueryParamsService.processReturnLink();
    });
  }

  getDetailLink() {
    return '/businessReview/' + this.apiFolder + '/timeline_view';
  }

  ngOnDestroy(): void {
    if (this.stateChangeHandler) {
      this.requestHandler.removeStateListener(this.stateChangeHandler);
    }
    this.detailQueryCompleteSubscription.unsubscribe();
    this.returnLinkSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.routeUrlSubscription.unsubscribe();
  }

  getQueryParams(dataItem) {
    let params = {};
    if ('uck' in dataItem) {
      params['ckey'] = dataItem['uck'];
    }
    if ('acct_pres_name' in dataItem) {
      params['acct_pres_name'] = dataItem.acct_pres_name.value;
    }
    if (dataItem['period']) {
      params['period'] = dataItem['period'];
    }
    if (this.apiData.uck) {
      params['uck'] = this.apiData.uck;
    }
    //console.log('LINE: 252 - Parameters', params);

    return this.detailQueryParamsService.getQueryParams(params);
  }

}
