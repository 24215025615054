import {Input, OnDestroy, OnInit} from '@angular/core';
import {TileReference} from '../../dashboards/tile-reference';
import { BaseTileComponent } from './base-tile.component';
import {RequestState} from '../../api/request-handler';

export abstract class TileComponent extends BaseTileComponent implements OnInit, OnDestroy {
  @Input() tileReference: TileReference;
  public apiData;
  public chartOptions;
  protected refreshHandleKey;
  protected requestHandler;

  ngOnInit(): void {
    this.setupPreferences();
    this.requestHandler = this.callApiSource(this.tileReference.apiSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        let apiData;
        try {
          apiData = JSON.parse(response.body);
          if (Array.isArray(apiData.values)) {
            let noDataTrue = true;
            if (apiData.values.length > 0) {
              for (const value of apiData.values) {
                if ('value' in value && (value.value !== 0 && value.value !== null)) {
                  noDataTrue = false;
                }
                if ('points' in value) {
                  for (const point of value.points) {
                    if (point !== 0) {
                      noDataTrue = false;
                    }
                  }
                }
              }
              if (noDataTrue) {
                this.state = RequestState.NoData;
              }
            }
          }
        } catch (e) {
          console.log('Invalid JSON returned for ' + this.tileReference.apiSource);
          this.state = RequestState.Error;
          if (response.body === undefined) {
            this.state = RequestState.NetworkError;
          }
          if (response.body.includes('Timeout expired') && response.body.includes('SQL')) {
            this.state = RequestState.SQLError;
          } else if (response.body.includes('SQL Error Message')) {
            this.state = RequestState.QueryOverload;
          }
        }
        if (apiData !== undefined) {
          this.setApiCallResponse(apiData, 0);
        }
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.tileReference.apiSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.tileReference.apiSource));
    // console.log('mycrl reParams', this.requestHandler.getRelativeParams(this.tileReference.apiSource));
    // console.log('getRelativeParams', this.requestHandler.getRelativeParams(this.tileReference.apiSource));
    // this.callApiSource(this.tileReference.apiSource2);
    // this.callApiSource(apiMappingLink, this.apiData[index], null);
    // this.translateApiDataForChart(this.apiData);
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
  }

  abstract translateApiDataForChart(apiData);

  setApiCallResponse(apiData, apiDataIndex) {
    this.apiData = apiData;
    this.translateApiDataForChart(this.apiData);
  }

  isDataFound(apiData) {
    if (apiData !== undefined && 'values' in apiData && apiData.values.length === 0) {
      return false;
    }
    if (this.preferences.hasOwnProperty('apiObjectKey')) {
      let rangeData = apiData.range[this.preferences.apiObjectKey];
      if ('dataexists' in rangeData && rangeData.dataexists === true) {
        return true;
      }
    }
    if (apiData !== undefined && 'values' in apiData && apiData.values.length > 0) {
      let atLeastOneValueNotNull = false;
      for (const item of apiData.values) {
        if (item.value !== null) {
          atLeastOneValueNotNull = true;
        }
      }
      if (!atLeastOneValueNotNull) {
        return false;
      }
      return true;
    }
    if (apiData !== undefined && 'dataexists' in apiData && apiData.dataexists === false) {
      return false;
    }
    return true;
  }

  nextHighestNumberDivisibleBy(num1, divisibleBy) {
    num1 = parseInt(num1);
    if (!Number.isInteger(num1)) {
      return false;
    }
    while (1) {
      if (num1 % divisibleBy === 0) {
        return num1;
      }
      num1++;
    }
  }
}
