import {Component, Input, OnInit} from '@angular/core';
import {RequestState} from '../request-handler';

@Component({
  selector: 'app-state-handler',
  templateUrl: './state-handler.component.html',
  styleUrls: ['./state-handler.component.scss']
})
export class StateHandlerComponent implements OnInit {
  RequestState = RequestState;
  @Input() requestState: RequestState;
  @Input() data;
  @Input() title;
  @Input() showTitle;
  @Input() noDataCheck: boolean = true;

  constructor() { }

  ngOnInit(): void {
    console.log(this.title, this.requestState, this.data);
  }

  hasData(data) {
    if (!data) {
      return false;
    }
    if (Array.isArray(data) && data.length === 0) {
      return false;
    }
    return true;
  }
}
